import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TermsAndConditionsComponent>
  ) {}

  ngAfterViewInit() {
    document.getElementById("scrollTotop").scroll(0, 0);
  }

  closeModal() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
