import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { BUSINESS } from "src/app/shared/consts";

@Injectable({
  providedIn: "root",
})
export class GlobalConstants {
  client: string;
  data: any;

  OFFER_IDS_FOR_WHOM_REEDEM_IS_HIDDEN: any = {
    //Had to redirect user to details for these offers and not claimable
    "88ca346a-6e02-401b-8c58-1465ad994a14": 0,
    "b969b505-e1ae-49cd-b6cb-95ed86b2e97a": 2,
  };

  businessCardConfig = {
    hideHomepage: true,
    hideMilestoneRewards: true,
    portalLink: "https://x.razorpay.com/cards",
    colors: {
      headerByColor: "#080D29",
      couponModalBgColor: "#1F2440",
      buttonActive: "#242A4A",
      buttonInactive: "#4F546E",
      blueColor: "#5CA2F7",
      detailsPageBg: "#161D40",
      subHeaderMobile: "#1E2442",
    },
    offerCardStyle: {
      cardBackgroundColor: "#242A4A",
      titleColor: "#FDFDFD",
      descriptionColor: "#BBBDC8",
      buttonActive: "#4F546E",
      buttonInactive: "#242A4A",
      boxShadow: "0px 7px 6px rgba(33, 34, 44, 0.25)",
      moreTagTextColor: "#FDFDFD",
      moreTagBgColor: "linear-gradient(90deg, #DF8700 0%, #DF6B00 100%)",
      moretagArrowColor: "#242A4A",
      showKnowMore: true,
      buttonFontSize: "14px",
      showKnowMoreBg: "#242A4A",
    },
    featuredCardStyling: {
      cardBackgroundColor:
        "linear-gradient(109.85deg, #122749 36.66%, #72430C 169.56%)",
      titleColor: "#FDFDFD",
      descriptionColor: "#BBBDC8",
      buttonActive: "#1566F1",
      buttonInactive: "#1566F1",
      buttonBorderColor: "",
      boxShadow: "inset rgb(1 17 79 / 62%) 0px 10px 20px 0px",
      moreTagTextColor: "#FDFDFD",
      moreTagBgColor: "linear-gradient(90deg, #DF8700 0%, #DF6B00 100%)",
      moretagArrowColor: "#092149",
      showKnowMore: true,
      buttonFontSize: "14px",
      showKnowMoreBg: "linear-gradient(134deg, #17132B, #1C172C)",
    },
    footer: {
      footer1Bg: "#111739",
      footer2Bg: "#161D40",
    },
    x_header: {
      prod: {
        client_id: "8472fcf3-5a9a-413a-92c0-9b430506ecb2",
        project_id: "11d6207f-06bb-403d-8e11-24e82a71db87",
      },
      sandbox: {
        client_id: "d6e70ecf-1ddb-413c-993d-6a0be53fa836",
        project_id: "7a535138-4b0e-4ca3-b631-64aa4a4ec183",
      },
      default_card_type: "credit",
    },
  };

  constructor(@Inject(DOCUMENT) private document: any) {
    let subDomain = this.document.location.hostname
      .split("www.")
      .join("")
      .split(".", 1);
    subDomain = subDomain
      .join("")
      .split("sandbox-")
      .join("")
      .split(".", 1)
      .shift();
    this.data = this.businessCardConfig;
    this.client = BUSINESS;
  }

  changeRootVariables() {
    var r: any = document.querySelector(":root");
  }
}
