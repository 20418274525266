import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { DOCUMENT } from '@angular/common';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  projectIdToHeader: string = "";

  constructor(@Inject(DOCUMENT) private document: any, public GlobalConstants: GlobalConstants, private authenticationService: AuthenticationService) {}

  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes("restcountries") || request.url.includes("ipify.org") || request.url.includes("postalpincode") || request.url.includes("opencagedata") || request.url.includes("ipstack")) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '-1',
        'X-Download-Options': 'noopen',
        'X-Client-Id': environment.client_id,
        "X-Project-Id": environment.project_id,
        "X-Country-Id": environment.country_id
      }
    });

    var currentUser;
    if (localStorage.getItem('currentUser')) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.token) {
        request = request.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + currentUser.token
          }
        });
      }
    }

    if(this.authenticationService.isTokenExpired() && window.location.pathname.includes("sso_login")) {
      return EMPTY;
    } else {
      return next.handle(request)
      .pipe(
        tap(
          (response: HttpEvent<any>) => {
            return response
          },
          (error: HttpErrorResponse) => {
            return error
          },
          () => {
          }
        )
      )
    }
  }
}
