import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalConstants } from './common/constants/global-variables';
import { AuthenticationService } from './common/services/authentication.service';
import { DeviceService } from './common/services/device.service';
import { TokenExpiredComponent } from './shared/components/shared/token-expired/token-expired.component';
import { ApiService } from './shared/services/api.service';
import { INDIA } from './shared/consts';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{
  public isAuthenticated: boolean = false;
  countries: Array<any> =[];
  selectedCountry: any = null;
  fullHeight: boolean = false;
  hideFor = ["offer-details"]
  fullHeightFor = ["offer-details"]
  isMobile: boolean = false;
  isTokenExpiredModalOpen: boolean = false;
  pageConfigs = [
      {
        path: "/rewards/offer-details",
        fullHeight: false,
      },
      {
        path: "/",
        fullHeight: false,
      },
      {
        path: "/claimed-rewards",
        fullHeight: false,
      },
  ]

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.deviceService.onResize();
  }

  constructor(
    public globalConstants: GlobalConstants, 
    private router: Router,
    private apiService: ApiService,
    private deviceService: DeviceService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {
    this.isMobile = this.deviceService.isMobile;
    this.deviceService.deviceObserver.subscribe((response) => {
      this.isMobile = response;
      this.setPageConfig();
    })
    this.setPageConfig();
    this.initializeSelectedCountry();
    this.authenticationService.tokenExpired.subscribe((isExpired) => {
      if(isExpired && !this.isTokenExpiredModalOpen) {
        this.isTokenExpiredModalOpen = true;
        this.dialog.open(TokenExpiredComponent, {panelClass: ["custom-modal", "token-expired"], disableClose: true});
      }
    })
  }

  setPageConfig() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(this.isMobile) {
          for(let i  = 0; i < this.pageConfigs.length; i++) {
            let config = this.pageConfigs[i];
            let currentPath = event.url.split('/').slice(0,3).join('/');
            if(currentPath === "/" && (currentPath === config.path)) {
              this.fullHeight = config.fullHeight;
              break;
            } else if(currentPath.includes(config.path) && config.path != "/" && config.path.includes(currentPath)) {
              this.fullHeight = config.fullHeight;
              break;
            }
          }
        } else {
          this.fullHeight = true;
        }
      }
    })
  }

  ngAfterViewInit() {
    document.getElementById("scrollTotop").scroll(0,0)
  }

  initializeSelectedCountry() {
    if (localStorage.getItem("countries")) {
      this.countries = JSON.parse(localStorage.getItem("countries"));
      if (localStorage.getItem("selectedCountry")) {
        this.selectedCountry = JSON.parse(
          localStorage.getItem("selectedCountry")
        );
      } else {
        this.getDefaultCountry(this.countries);
      }
    } else {
      this.getAllCountries();
    }
  }

  getDefaultCountry(countries) {
    let filteredCountry = countries.filter((x) => {
      return x.attributes.name === INDIA;
    });
    if (filteredCountry.length > 0) {
      let selectedCountry = filteredCountry[0];
      let mobile_length = selectedCountry.attributes.mobile_length.split("...");
      selectedCountry.attributes.min_mobile_digit = parseInt(mobile_length[0]);
      selectedCountry.attributes.max_mobile_digit = parseInt(mobile_length[1]) - 1;
      localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry));
      this.selectedCountry = selectedCountry;
    }
  }

  getAllCountries() {
    if (
      !localStorage.getItem("selectedCountry") ||
      (localStorage.getItem("selectedCountry") &&
        Object.keys(JSON.parse(localStorage.getItem("selectedCountry")))
          .length === 0)
    ) {
      this.apiService.getAllCountries().subscribe((res: any) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.countries = res.data;
          this.getDefaultCountry(res.data);
          localStorage.setItem("countries", JSON.stringify(res.data));
        }
      });
    }
  }
}
