import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { AuthGuard } from "./common/services/auth.guard";

const routes: Route[] = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/homepage/homepage.module").then((m) => m.HomepageModule),
  },
  {
    path: "rewards",
    loadChildren: () =>
      import("./pages/rewards/rewards.module").then((m) => m.RewardsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: "claimed-rewards",
    loadChildren: () =>
      import("./pages/claimed-rewards/claimed-rewards.module").then(
        (m) => m.ClaimedRewardsModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: "info",
    loadChildren: () =>
      import("./pages/info/info.module").then((m) => m.InfoModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
