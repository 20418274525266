import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryBasedRewardsComponent } from './category-based-rewards/category-based-rewards.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CouponModalComponent } from './coupon-modal/coupon-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { SelectOfferComponent } from './select-offer/select-offer.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { DeviceService } from 'src/app/common/services/device.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FooterComponent } from './footer/footer.component';
import { TokenExpiredComponent } from './token-expired/token-expired.component';
import { OfferClaimedCardComponent } from './offer-claimed-card/offer-claimed-card.component';
import { IndianCurrencyPipe } from 'src/app/common/pipes/indian-currency/indian-currency.pipe';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { FilterPipe } from 'src/app/common/pipes/filter/filter.pipe';
import {MatTooltipModule} from "@angular/material/tooltip";
import { NumbersOnlyDirective } from 'src/app/common/directives/numbers-only.directive';
import { BenefitsMenuComponent } from './benefits-menu/benefits-menu.component';
import { OfferCardComponent } from './offer-card/offer-card.component';


@NgModule({
  declarations: [
    CategoryBasedRewardsComponent,
    HeaderComponent,
    CouponModalComponent,
    SelectOfferComponent,
    AuthenticationComponent,
    MobileNavComponent,
    FooterComponent,
    TokenExpiredComponent,
    OfferClaimedCardComponent,
    IndianCurrencyPipe,
    FilterPipe,
    NumbersOnlyDirective,
    BenefitsMenuComponent,
    OfferCardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forChild(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
    }),
    FormsModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    TabsModule,
    BsDatepickerModule.forRoot(),
    MatTooltipModule,
  ],
  exports: [
    CategoryBasedRewardsComponent,
    HeaderComponent,
    FooterComponent,
    ModalModule,
    BsDropdownModule,
    AuthenticationComponent,
    FormsModule,
    ToastrModule,
    ReactiveFormsModule,
    MobileNavComponent,
    OfferClaimedCardComponent,
    IndianCurrencyPipe,
    NgxSkeletonLoaderModule,
    BsDatepickerModule,
    FilterPipe,
    MatTooltipModule,
    NumbersOnlyDirective,
    OfferCardComponent,
  ],
  providers: [DeviceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
