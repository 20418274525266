import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { AuthenticationComponent } from '../authentication/authentication.component';

@Component({
  selector: 'app-token-expired',
  templateUrl: './token-expired.component.html',
  styleUrls: ['./token-expired.component.scss']
})
export class TokenExpiredComponent {
  public dialogRef: any;
  public isAuthenticated?: boolean;

  constructor(public globalConstants: GlobalConstants, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) { }

  handleBackToLogin() {
    this.addLoggedInStatusToRoute(false);
    if(!this.dialogRef) {
      document.body.classList.add("overflow-hidden")
      this.dialogRef = this.dialog.open(AuthenticationComponent, { disableClose: true, panelClass: ["authenticationModal"] }).afterClosed().subscribe((response) => {
        if(response) {
          this.dialogRef = null;
          this.isAuthenticated = true;
          location.reload();
        }
      });
    }
  }

  private addLoggedInStatusToRoute(isLoggedIn: boolean) {
    if(this.route.snapshot.queryParams.isLoggedIn) {
      return;
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { isLoggedIn }, 
        queryParamsHandling: 'merge'
    });
  }
}
