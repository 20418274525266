import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationComponent } from 'src/app/shared/components/shared/authentication/authentication.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { GlobalConstants } from '../constants/global-variables';
import { ACTIVATED, BUSINESS } from 'src/app/shared/consts';

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public isUserLoggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  public tokenExpired: EventEmitter<boolean> = new EventEmitter<boolean>();
  dialogRef: any;

  toBeRemoved = [];

  constructor(
    private _apiService: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private globalConstants: GlobalConstants,
    private route: ActivatedRoute
  ) {
    if (
      localStorage.getItem("currentUser") &&
      !window.location.pathname.includes("sso_login")
    ) {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      if (currentUser && currentUser.token) {
        const token = JSON.parse(atob(currentUser.token.split(".")[1]));
        const expiresOn = new Date(token.exp * 1000);
        if (expiresOn < new Date(Date.now())) {
          this.setTokenExpiredState();
        } else {
          this.isUserLoggedIn.emit(true);
          setTimeout(() => {
            this.setTokenExpiredState();
          }, Math.abs(Math.floor(new Date().getTime() - token.exp * 1000)));
        }
      }
    }
  }

  isTokenExpired() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && currentUser.token) {
      const token = JSON.parse(atob(currentUser.token.split(".")[1]));
      const expiresOn = new Date(token.exp * 1000);
      if (expiresOn < new Date(Date.now())) {
        return true;
      }
    }
  }

  setTokenExpiredState() {
    localStorage.removeItem("userDetails");
    if (localStorage.getItem("currentUser")) {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      currentUser.token = "";
      currentUser.isTokenExpired = true;
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    }
    this.router.navigate(["/"]);
    this.tokenExpired.emit(true);
  }

  public checkIfUserAuthenticated(): void {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "null");

    if (!currentUser || currentUser?.isTokenExpired) {
      this.isUserLoggedIn.emit(false);
      this.addLoggedInStatusToRoute(false);
      return;
    }

    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "null");
    if (userDetails?.mobile && userDetails?.status === ACTIVATED) {
      this.addLoggedInStatusToRoute(true);
      this.isUserLoggedIn.emit(true);
      this.tokenExpired.emit(false);
      document.body.className.replace("overflow-hidden", "").trim();
      this.dialog.closeAll();
      if (!window.location.pathname.includes("/info") && !window.location.pathname.includes("/claimed-rewards")) {
        this.router.navigate(["/"]);
      }
    }
  }

  private addLoggedInStatusToRoute(isLoggedIn: boolean) {
    if (this.route.snapshot.queryParams.isLoggedIn) {
      return;
    }

    if (
      !window.location.pathname.includes("/info") &&
      !window.location.pathname.includes("/claimed-rewards")
    ) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { isLoggedIn },
        queryParamsHandling: "merge",
      });
    }
  }

  showAuthenticationModal() {
    if (!this.dialogRef) {
      document.body.classList.add("overflow-hidden");
      this.dialogRef = this.dialog
        .open(AuthenticationComponent, {
          disableClose: true,
          panelClass: ["authenticationModal"],
        })
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.dialogRef = null;
            if (this.globalConstants.client === BUSINESS) {
              location.reload();
            }
          }
        });
    }
  }
}
