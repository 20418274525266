<div class="token-expired-modal d-flex flex-column pad-36 justify-content-center align-items-center rounded" [ngStyle]="{background: globalConstants.data.colors.couponModalBgColor}">
    <img src="../../../../../assets/images/hourglass.svg" class="hourglass mar-top-36" />
    <div class="text-center fnt-24 f700 mar-top-50 mar-bot-15 cfff">
        User Session Expired!
    </div>
    <div class="fnt-13 f400 cfff text-center mar-bot-25 pad-10">
        You have been logged out. Please re-authenticate to access rewards.
    </div>
    <div class="custom-button primary pad-top-15 pad-bot-15 pad-left-20 pad-right-20 border-rounded-2 mar-bot-24 pointer" (click)="handleBackToLogin ()">
        Back to Login
    </div>
</div>