import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { GlobalConstants } from "src/app/common/constants/global-variables";
import { AuthenticationService } from "src/app/common/services/authentication.service";
import { DeviceService } from "src/app/common/services/device.service";
import { TermsAndConditionsComponent } from "src/app/pages/info/terms-and-conditions/terms-and-conditions.component";
import { INDIA } from "src/app/shared/consts";
import { ApiService } from "src/app/shared/services/api.service";

@Component({
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.scss"],
})
export class AuthenticationComponent implements OnInit {
  stepToShow: string = "mobile";
  createWithUserForm: any = this.formBuilder.group({
    mobile: ["", [Validators.required]],
  });
  userId: string = "";

  //For Step 1 (mobile search)
  selectedCountry: any;
  countrySearchTerm: string = "";
  filteredCountries: Array<{}> = [];
  searchMobileLoader: boolean = false;
  mobileInputBtnActive: boolean = false;
  isMobileNumberSearching: boolean = false;
  errorRequestingOtpMsg: string = "";
  errorRequestingOtp: boolean = false;
  mobNum: string = "";
  selectedCountryAvailable: boolean = false;
  public editing: boolean = false
  showMobileInvalidMessage: boolean = false;

  //For step 2 (otp search)
  otpInp: any;
  validOtp: boolean = false;
  validateUserOtpForm: any = this.formBuilder.group({
    otp: [""],
  });
  hideResend: boolean = false;
  invalidOtp: boolean = false;
  submittedOtpForm: boolean = false;
  otpVerifying: boolean = false;

  timer: any = "initial";
  viewTimer: any = 0;
  timerToShow: any;

  userCards: Array<any> = [];
  showAddCardComponent: boolean = false;
  loadingCards: boolean = false;

  countries: Array<any> = [];

  @ViewChild("firstFocusable") firstFocusable: ElementRef;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    public GlobalConstants: GlobalConstants,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<AuthenticationComponent>,
    public deviceService: DeviceService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if (localStorage.getItem("countries")) {
      this.countries = JSON.parse(localStorage.getItem("countries"));
      this.filteredCountries = this.countries;
      let india;
      this.filteredCountries = this.filteredCountries.filter((country: any) => {
        if (country.attributes.name.toLowerCase() != "india") {
          return true;
        } else {
          india = country;
          return false;
        }
      });
      this.filteredCountries.sort((a: any, b: any) => {
        if (
          a.attributes.name.charAt(0).toLowerCase() <
          b.attributes.name.charAt(0).toLowerCase()
        ) {
          return -1;
        }
        if (
          a.attributes.name.charAt(0).toLowerCase() >
          b.attributes.name.charAt(0).toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });
      this.filteredCountries.unshift(india);
      if (localStorage.getItem("selectedCountry")) {
        this.selectedCountry = JSON.parse(
          localStorage.getItem("selectedCountry")
        );
        this.selectedCountryAvailable = true;
      } else {
        this.getDefaultCountry(this.countries);
      }
    } else {
      this.getAllCountries();
    }
  }

  getAllCountries() {
    if (
      !localStorage.getItem("selectedCountry") ||
      (localStorage.getItem("selectedCountry") &&
        Object.keys(JSON.parse(localStorage.getItem("selectedCountry")))
          .length === 0)
    ) {
      this.apiService.getAllCountries().subscribe((res: any) => {
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.countries = res.data;
          this.getDefaultCountry(res.data);
          localStorage.setItem("countries", JSON.stringify(res.data));
        }
      });
    }
  }

  getDefaultCountry(countries) {
    let filteredCountry = countries.filter((x) => {
      return x.attributes.name === INDIA;
    });
    if (filteredCountry.length > 0) {
      let selectedCountry = filteredCountry[0];
      let mobile_length = selectedCountry.attributes.mobile_length.split("...");
      selectedCountry.attributes.min_mobile_digit = parseInt(mobile_length[0]);
      selectedCountry.attributes.max_mobile_digit =
        parseInt(mobile_length[1]) - 1;
      localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry));
      this.selectedCountry = selectedCountry;
      this.selectedCountryAvailable = true;
    }
  }

  filterItem(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.filteredCountries = Object.assign([], this.countries).filter(
        (item) =>
          item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
          -1
      );
    }
  }

  setCountry(country) {
    this.selectedCountry = country;
  }

  clearAll() {
    this.otpVerifying = false;
    this.searchMobileLoader = false;
    this.errorRequestingOtpMsg = "";
    this.createWithUserForm.patchValue({ mobile: "" });
    this.otpInp = undefined;
  }

  removeLetter(str) {
    if (parseInt(str) < 1) {
      str = "";
      this.createWithUserForm.controls["mobile"].setValue("");
    }
    this.createWithUserForm.controls["mobile"].setValue(str.replace(/\D/g, ""));
  }

  enableContinueButton() {
    if (
      this.createWithUserForm.value.mobile.length >=
        this.selectedCountry.attributes.min_mobile_digit &&
      this.createWithUserForm.value.mobile.length <=
        this.selectedCountry.attributes.max_mobile_digit
    ) {
      this.mobileInputBtnActive = true;
    } else {
      this.mobileInputBtnActive = false;
    }
  }

  userSearchByMobile() {
    if (this.createWithUserForm.valid) {
      this.showMobileInvalidMessage =
        !this.createWithUserForm.controls.mobile.valid;
      this.isMobileNumberSearching = true;
      this.apiService
        .searchExistingUser(this.createWithUserForm.value.mobile)
        .pipe(
          finalize(() => {
            this.isMobileNumberSearching = false;
          })
        )
        .subscribe(
          (response: any) => {
            if (response) {
              this.userId = response?.id;
              this.submittedOtpForm = false;
              this.countDown(60, () => {
                this.timer = "complete";
              });
              this.requestPoshvineOtp();
              this.stepToShow = "otp";
              setTimeout(() => {
                document.getElementById("otp-field").focus();
                this.otpInp = "";
              }, 500);
            } else {
              this.errorRequestingOtp = true;
              this.errorRequestingOtpMsg = "User does not exist.";
            }
          },
          (err) => {
            if (err.status && err.status === 422) {
              this.errorRequestingOtp = true;
              if (
                err.error &&
                err.error.errors &&
                err.error.errors[0] === "Locked due to max wrong OTP attempts"
              ) {
                this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${this.checkTime(
                  err.error.user_unlock_at
                )} mins.</div>`;
              } else {
                this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${this.checkTime(
                  err.error.next_otp_at
                )} mins.</div>`;
              }
            } else if (err.status && err.status === 429) {
              this.errorRequestingOtp = true;
              this.errorRequestingOtpMsg = "Please retry after some time.";
            }
            this.errorRequestingOtp = true;
            this.isMobileNumberSearching = false;
          }
        );
    } else {
      this.showMobileInvalidMessage =
        !this.createWithUserForm.controls.mobile.valid;
    }
  }

  resetLoginAgain() {
    this.editing = true
    this.clearAll();
    this.searchMobileLoader = false;
    this.stepToShow = "mobile";
    this.errorRequestingOtp = false;
    this.errorRequestingOtpMsg = "";

    if (this.createWithUserForm.controls) {
      if (this.createWithUserForm.controls["mobile"]) {
        this.createWithUserForm.controls["mobile"].setValue("");
      }
      if (this.validateUserOtpForm.controls["otp"]) {
        this.validateUserOtpForm.controls["otp"].setValue("");
      }
    }
    this.otpInp = "";
    this.validOtp = false;
    clearInterval(this.int), function () {};
  }

  int: any;
  countDown(i, callback) {
    clearInterval(this.int);
    callback = callback || function () {};
    this.int = setInterval(() => {
      this.timer = "started";
      this.viewTimer = i;
      const minutes: number = Math.floor(i / 60);
      this.timerToShow =
        minutes + ":" + (i - minutes * 60).toString().padStart(2, "0");

      i-- || (clearInterval(this.int), callback());
    }, 1000);
  }

  resetCountDown() {
    clearInterval(this.int);
  }

  clearOtp() {
    document.getElementById("otp-field").focus();
    this.otpInp = "";
  }

  checkTime(userUnlockSeconds) {
    return moment(userUnlockSeconds).diff(moment(), "minutes");
  }

  resendOtp() {
    this.errorRequestingOtpMsg = "";
    this.submittedOtpForm = true;
    this.requestPoshvineOtp(false);
  }

  requestPoshvineOtp(goTonextStep = true) {
    this.apiService
      .requestUserOtp(
        this.createWithUserForm.value.mobile,
        this.userId,
        this.selectedCountry.attributes.calling_codes[0]
      )
      .subscribe(
        (response) => {
          if (response) {
            this.errorRequestingOtp = false;
            this.submittedOtpForm = false;
            this.countDown(60, () => {
              this.timer = "complete";
            });
            if (goTonextStep) {
              this.stepToShow = "otp";
            }
            setTimeout(() => {
              document.getElementById("otp-field").focus();
              this.otpInp = "";
            }, 500);
          } else {
            this.errorRequestingOtpMsg = `<div>Too many incorrect attempts. </div> <div class = "pad-top-5">Please try again after 30 mins</div>`;
            this.errorRequestingOtp = true;
            this.submittedOtpForm = false;
          }
          this.isMobileNumberSearching = false;
        },
        (error) => {
          this.isMobileNumberSearching = false;
          this.submittedOtpForm = false;
          if (error.status === 422) {
            if (
              error.error &&
              error.error.errors &&
              error.error.errors[0] === "Locked due to max wrong OTP attempts"
            ) {
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${
                this.checkTime(error.error.user_unlock_at) || "few"
              } mins.</div>`;
            } else {
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${
                this.checkTime(error.error.next_otp_at) || "few"
              } mins.</div>`;
            }
            this.errorRequestingOtp = true;
          } else {
            this.toastr.error("Something went wrong. Please try again!");
          }
        }
      );
  }

  openTermsAndConditions() {
    this.dialog.open(TermsAndConditionsComponent, {
      panelClass: ["custom-modal", "footer-modals"],
      data: {
        showCloseButton: true,
      },
    });
  }

  validateUserOtp() {
    if (this.otpInp && this.otpInp.length === 6) {
      this.otpVerifying = true;
      this.apiService
        .validateUserOtp(this.userId, this.otpInp)
        .subscribe((response) => {
          let currentUser = {
            token: response.token,
            isTokenExpired: false,
          };
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
          this.getUserDetails();
        });
    }
  }

  public getUserDetails(): void {
    this.apiService
      .getUserDetails()
      .pipe(
        finalize(() => {
          this.otpVerifying = false;
        })
      )
      .subscribe(
        (res: any) => {
          if (res) {
            localStorage.setItem("userDetails", JSON.stringify(res));
            this.matDialogRef.close(true);
            this.authenticationService.checkIfUserAuthenticated();
          } else {
            this.errorRequestingOtp = true;
            if (res.wrong_attempts_left === 0) {
              this.hideResend = true;
              let resendWaitTime = this.checkTime(res.user_unlock_at) || "few";
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Too many incorrect attempts. Please try again after ${resendWaitTime} mins.</div>`;
            } else {
              this.errorRequestingOtpMsg = `<div class = "pad-top-3">Incorrect otp. Please try again.</div> <div class = "pad-top-3">${
                res.wrong_attempts_left
              } attempts${res.wrong_attempts_left === 1 ? "" : "s"} left.</div>`;
            }
          }
        },
        (err) => {
          //toastr message may be or need to ask user to login
          this.errorRequestingOtp = true;
          if (err.status === 404) {
            this.errorRequestingOtpMsg = "Otp expired";
            this.stepToShow = "mobile";
            this.clearOtp();
            setTimeout(() => {
              this.errorRequestingOtp = false;
              this.errorRequestingOtpMsg = "";
            }, 3000);
          } else {
            this.errorRequestingOtpMsg =
              "Something went wrong! Please try again later";
          }
        }
      );
  }

  ngOnDestroy() {
    this.matDialogRef.close(true);
  }
}
