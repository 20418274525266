<div class="darkBg px-3 px-md-5">
  <div class="d-flex flex-row pt-3 pb-3 pb-md-0 w-full align-items-center">
    <div
      class="card-img d-flex"
      [style]="{ marginRight: 'auto' }"
      (click)="router.navigate(['/'])"
    >
      <img
        src="https://images.poshvine.com/razorpayx/Yes-Bank-logo-inverse.png"
        class="mar-right-8 header-logo"
        alt="yes-bank-logo"
      />
      <img
        src="https://images.poshvine.com/razorpayx/RZPX-light.png"
        class="header-logo"
        alt="razorpay-x-logo"
      />
    </div>
    <div
      class="searchContainer mar-right-24 pointer fnt-16"
      (click)="openSearch()"
    >
      <ng-template #thenBlock>
        <input
          placeholder="Search"
          [(ngModel)]="searchWord"
          autofocus
          (input)="onSearchWordChange()"
          class="pad-4 search-input"
          (blur)="handleInputBlur()"
        />
      </ng-template>

      <ng-template #elseBlock>
        <div class="cfff d-flex align-items-center">
          <span class="icon-search fnt-16 cfff pad-top-5"></span>
          <p class="mb-0 mar-left-8">Search</p>
        </div>
      </ng-template>

      <div *ngIf="searching; then thenBlock; else elseBlock"></div>
    </div>
    <button
      class="relative custom-button primary border-rounded-2 btn d-flex justify-content-center px-4"
      type="button"
      (click)="isAuthenticated ? logout() : showAuthenticationModal()"
    >
      {{ !isAuthenticated ? "Login" : "Logout" }}
    </button>
  </div>
  <div class="d-none d-sm-flex flex-column h100 justify-content-end">
    <div
      class="d-flex flex-row tab-styling mt-2 relative overflow-auto hide-scrollbar"
    >
      <div
        class="tab-name pointer text-nowrap explore-rewards"
        [ngClass]="{
          active:
            this.getLocationPath() === '/' ||
            this.router.url.includes('offer-details')
        }"
        routerLink="/"
      >
        Explore Rewards
      </div>
      <div
        class="tab-name pointer text-nowrap claimed-rewards"
        [ngClass]="{ active: this.router.url?.includes('/claimed-rewards') }"
        routerLink="/claimed-rewards"
      >
        Claimed Rewards
      </div>
    </div>
  </div>
</div>
