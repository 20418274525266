import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/common/services/authentication.service";
import { ApiService } from "src/app/shared/services/api.service";
import { AuthenticationComponent } from "../authentication/authentication.component";
import { MerchantsService } from "src/app/common/services/merchants.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isAuthenticated: boolean = false;
  isEmailVerified: boolean = false;
  private dialogRef: any;
  visible: boolean = false;
  searching: boolean = false;
  searchWord: string = "";
  private searchWordChanged: Subject<string> = new Subject();

  showDialog() {
    this.visible = true;
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private merchantService: MerchantsService,
    private dialog: MatDialog
  ) {
    this.authService.isUserLoggedIn.subscribe((isLoggedIn) => {
      this.isAuthenticated = !!isLoggedIn;
    });
    this.authService.tokenExpired.subscribe((isExpired) => {
      this.isAuthenticated = !isExpired;
    });
    this.searchWordChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.merchantService.searchMerchantEE.emit(this.searchWord.trim());
      });
  }

  public ngOnInit(): void {
    this.authService.checkIfUserAuthenticated();
    // side effect here
  }

  public getLocationPath() {
    return window.location.pathname;
  }

  checkIfEmailIsVerified() {
    this.apiService.getUserDetails().subscribe((userDetails) => {
      if (userDetails.email_verified) {
        this.isEmailVerified = true;
      } else {
        this.isEmailVerified = false;
      }
    });
  }

  public showAuthenticationModal(): void {
    this.addLoggedInStatusToRoute(false);
    if (!this.dialogRef) {
      document.body.classList.add("overflow-hidden");
      this.dialogRef = this.dialog
        .open(AuthenticationComponent, {
          disableClose: false,
          panelClass: ["authenticationModal"],
        })
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            this.dialogRef = null;
            this.isAuthenticated = true;
            location.reload();
          }
        });
    }
  }

  private addLoggedInStatusToRoute(isLoggedIn: boolean) {
    if (this.route.snapshot.queryParams.isLoggedIn) {
      return;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { isLoggedIn },
      queryParamsHandling: "merge",
    });
  }

  public logout(): void {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("targetUrl");
    this.authService.isUserLoggedIn.emit(false);
    sessionStorage.clear();
    location.reload();
  }

  public openSearch() {
    this.searching = true;
  }

  public onSearchWordChange(): void {
    this.searchWordChanged.next(this.searchWord.trim());
  }

  public handleInputBlur() {
    if (!this.searchWord.trim()) {
      this.searching = false;
    }
  }
}
