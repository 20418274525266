import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { GlobalConstants } from "src/app/common/constants/global-variables";
import { ApiService } from "src/app/shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { RedemptionService } from "src/app/shared/services/redemption.service";

interface offer {
  id: string;
  merchantLogoUrl: string;
  offerType: string;
  endDate: string;
  merchantName: string;
  redemptionUrl: string;
  offerTitle: string;
}

@Component({
  selector: "app-coupon-modal",
  templateUrl: "./coupon-modal.component.html",
  styleUrls: ["./coupon-modal.component.scss"],
})
export class CouponModalComponent implements OnInit {
  selectedOffer: any;
  copiedCode: boolean = false;
  couponLoading: boolean;
  showCopiedState: boolean = false;
  redeemActive: boolean = false;
  redeeming = false;
  voucherData: any = {};
  hideRedeemButton: boolean = false;
  showCustomRedeemMessage: boolean = false;

  customRedeemMessage = {
    "0c8691d8-6a4d-4643-a796-85fd7d95c8a8":
      "Reach out to <a href='mailto:support@infiflex.com' class='mar-left-1 blue-text'>support@infiflex.com</a> to avail the benefit.", //sandbox
    "88ca346a-6e02-401b-8c58-1465ad994a14": "Successfully Enrolled!",
    "a8df443b-20cc-428b-b5e9-a4c329549e75": "Swipe your RazorpayX Card",
  };

  emitRedeem = new EventEmitter<boolean>();
  termsOpen: EventEmitter<any> = new EventEmitter();
  hideEvent: EventEmitter<boolean> = new EventEmitter();

  public constructor(
    public GlobalConstants: GlobalConstants,
    private _apiService: ApiService,
    private toastr: ToastrService,
    public _bsModalRef: BsModalRef,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private redemptionService: RedemptionService
  ) {}

  ngOnInit(): void {
    this.proceedToRedeem();
  }

  hideRedeemForOfferIds = [
    "326f9573-d664-4e0e-8564-d1d9bff43abf",
    "88ca346a-6e02-401b-8c58-1465ad994a14",
  ];

  proceedToRedeem() {
    this.couponLoading = true;
    this.changeDetector.detectChanges();
    let params = {};
    this.hideRedeemButton = this.hideRedeemForOfferIds.includes(
      this.selectedOffer.id
    );
    if (Object.keys(this.customRedeemMessage).includes(this.selectedOffer.id)) {
      this.hideRedeemButton = true;
      this.showCustomRedeemMessage = true;
    }
    this._apiService.redeem({ id: this.selectedOffer.id }, params).subscribe(
      (res: any) => {
        this.voucherData = res;
        this.couponLoading = false;
        this.changeDetector.detectChanges();
        this.redemptionService.getPerksData();
      },
      (err) => {
        if (err.status && err.status === 401) {
          this._bsModalRef.hide();
        } else {
          this.couponLoading = false;
          this.voucherData.success = false;
        }
        this.changeDetector.detectChanges();
      }
    );
  }

  goTo() {
    let gotourl = this.selectedOffer.redemptionUrl;
    window.open(gotourl, "_blank");
  }

  redirectToMerchantTerms() {
    this.router.navigate([
      "/rewards/offer-details/" +
        this.selectedOffer.merchantId +
        (this.selectedOffer.isFeatured ? "/featured" : ""),
      { showTerms: true },
    ]);
    this._bsModalRef.hide();
  }

  copy(code) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toastr.success("Copied!");
    this.showCopiedState = true;
    setTimeout(() => {
      this.changeDetector.detectChanges();
      this.showCopiedState = false;
    }, 2000);
  }

  closeModal() {
    this._bsModalRef.hide();
  }
}
