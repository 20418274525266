export const environment = {
  production: true,
  client_id: "8472fcf3-5a9a-413a-92c0-9b430506ecb2",
  project_id: "9d56e391-208e-49c2-875c-a67ac8a3ec09",
  country_id: "625a3e8e-e493-421a-8ece-609f10b37442",
  corePathV2: "https://api.poshvine.com/cs/v1",
  offerPathV2: "https://api.poshvine.com/ps/v1",
  apiUrlPaV1: "https://api.poshvine.com/pa/v1/",
  apiUrlBsV1: "https://api.poshvine.com/bs/v1/",
  sentryDsn: "https://6677f33409fa2725d780a04fcfdb19fc@o515678.ingest.us.sentry.io/4506942786174976",
  SENTRY_ORG:"rzp",
  SENTRY_PROJECT:"rzp-merchant-rewards",
  SENTRY_AUTH_TOKEN:"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",
  pg_freePgCredits: "3c11809c-77b7-477c-8727-bb74746d2285",
  razorpayx_cc_annualFee: "f510f96d-0d68-4a6a-af8b-b69e06b88650",
  razorpayx_cc_dedicatedRmRazorpay: "c91cbf08-6c8f-4221-b0d6-146ca2df984f",
  razorpayx_current_acc_freePayrollMonths: "1ab07e74-5593-4407-b03d-95cee5e5e198",
  razorpayx_current_acc_freePayouts: "5628f982-18df-4b9a-87c9-9b7f489e8046",
  businessRewardsTrackingId: "GTM-KG3BM62",
};
