import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { GlobalConstants } from 'src/app/common/constants/global-variables';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SelectOfferComponent } from '../components/shared/select-offer/select-offer.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CouponModalComponent } from '../components/shared/coupon-modal/coupon-modal.component';
import { DeviceService } from 'src/app/common/services/device.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

	projectSearchTerm = new BehaviorSubject("");

  	constructor(
		private http: HttpClient,
		private GlobalConstants: GlobalConstants,
		@Inject(DOCUMENT) private document: any,
		private dialog: MatDialog,
		private modalService: BsModalService,
		private deviceService: DeviceService,
		private router: Router
	) {}

	private navigateToMileStoneRewardsForSelectedOffer(offerId: string): void {
		this.router.navigate(
			['/milestone-rewards'],
			{
				queryParams: {
					tabIndex: this.GlobalConstants.OFFER_IDS_FOR_WHOM_REEDEM_IS_HIDDEN[offerId]
				},
				queryParamsHandling: 'merge'
			}
		);
	}

	claimOffer(merchant) {
		let hideRedeemForOfferIds = this.GlobalConstants.OFFER_IDS_FOR_WHOM_REEDEM_IS_HIDDEN ? Object.keys(this.GlobalConstants.OFFER_IDS_FOR_WHOM_REEDEM_IS_HIDDEN) : [];
		if(merchant?.attributes?.offers?.data?.length === 1) {
			if(hideRedeemForOfferIds.includes(merchant.attributes.offers.data[0].id)) {
				this.navigateToMileStoneRewardsForSelectedOffer(merchant.attributes.offers.data[0].id);
			} else {
				this.offerRedeem(merchant.attributes.offers.data[0], merchant);
			}
		} else if(merchant?.attributes?.offers?.data?.length > 1) {
			this.dialog.open(SelectOfferComponent, {
			data: {merchantName: merchant.attributes?.name, merchantLogo: merchant.attributes?.logo_url, offers: merchant.attributes?.offers.data},
			panelClass: this.deviceService.isMobile ? ['animate__animated', 'animate__fadeInUp', 'select-offer-modal'] : 'select-offer-modal'
			}).afterClosed().subscribe((offer: any) => {
				if(offer) {
					if(hideRedeemForOfferIds.includes(offer.id)) {
						this.navigateToMileStoneRewardsForSelectedOffer(offer.id);
					} else {
						this.offerRedeem(offer, merchant);
					}
				}
			})
		}
	}

	offerRedeem(offer, merchant) {
		this.modalService.show(CouponModalComponent, {initialState: {selectedOffer: this.processSelectedOffer(offer, merchant)}, class: "modal-dialog-centered m-auto custom-modal coupon-modal"});
	}

	createUser(mobile: string, email: string, calling_code, country_id) {
		return this.http.post(environment.corePathV2 + '/users', {
			mobile: mobile, email: email, calling_code, country_id
		}).pipe(
			map(res => {
				return res;
			}),
			catchError((err: HttpErrorResponse) => {
				return throwError(err);
			})
		);
	}

	requestUserOtp(mobile: string, userId: string, calling_code: string) {
		return this.http.post<any>(environment.corePathV2 + '/users/' + userId + '/otps', { mobile: mobile, calling_code: calling_code })
		.pipe(
			map(res => {
				return res;
			}),
			catchError((err: HttpErrorResponse) => {
				return throwError(err);
			})
		);
	}

	validateUserOtp(userId: string, otp: string) {
		return this.http.post<any>(environment.corePathV2 + '/users/' + userId + '/otps/verify', { 'otp_type':'login',code: otp })
			.pipe(
				map(res => {
					return res;
				}),
				catchError((err: HttpErrorResponse) => {
					return throwError(err);
				})
			);
	}

	cardValidation(bin) {
		return this.http.post<any>(environment.corePathV2 + "/bins/validate", {bin: bin}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}

  	validateExistingUser(mobile: string, calling_code: string, country_id) {
		return this.http.post<any>(environment.corePathV2 + '/users/verify', {mobile: mobile, calling_code: calling_code, country_id:country_id})
	  		.pipe(map(res => {
		  		return res;
			}),
			catchError((err: HttpErrorResponse) => {
		  		return throwError(err);
			})
	  	);
	}

	public searchExistingUser(mobile: string) {
		return this.http.post<any>(environment.corePathV2 + '/users/search', {mobile: mobile})
	  		.pipe(map(res => {
		  		return res;
			}),
			catchError((err: HttpErrorResponse) => {
		  		return throwError(err);
			})
	  	);
	}

  	getAllCountries() {
		return this.http.get(environment.corePathV2 + "/countries").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  	getUser(userId) {
		return this.http.get<any>(environment.corePathV2 + '/users/' + userId, {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}

	getUserDetails() {
		return this.http.get<any>(environment.corePathV2 + '/user', {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}

	patchUserDetails(data) {
		return this.http.patch(environment.corePathV2 + "/user", data);
	}

	saveProfile(data) {
		return this.http.put(environment.corePathV2 + "/user", data);
	}

  	binValidation(bin) {
		return this.http.get<any>(environment.offerPathV2 + "/bins/card_details?bin=" + bin).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}

	validateCard(bin, bin_type) {
		return this.http.post<any>(environment.corePathV2 + "/user/user_cards/search",{bin:bin, bin_type:bin_type}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	addCard(bin, bin_type ){
		return this.http.post<any>(environment.corePathV2 + "/user/user_cards", {bin: bin, bin_type : bin_type }).pipe(
		  map(res => res || []),
		  catchError(error => throwError(error))
		);
	}

	getCards(params = {}) {
		return this.http.get<any>(environment.corePathV2 + '/user/user_cards', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}

  	getOffers(params) {
		return this.http.get(environment.offerPathV2 + "/offers?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	processSelectedOffer(offer, merchant) {
		return {
		  id: offer.id,
		  merchantLogoUrl: merchant?.attributes?.logo_url,
		  offerType: offer?.attributes?.offer_type,
		  endDate: offer?.attributes?.end_date,
		  offerTitle: offer?.attributes?.title,
		  merchantName: merchant?.attributes?.name,
		  merchantId: merchant.id,
		  isFeatured: merchant.isFeatured,
		  redemptionUrl: offer?.attributes?.redemption_url != null ? offer?.attributes?.redemption_url : merchant?.attributes?.website,
		}
	}

  	getCategories(params,parent_project = false) {
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPathV2 + "/categories?elgble=true", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  	getCategoryDetails(params = {}, parent_project = false) {
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPathV2 + "/category_details?elgble=true", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  	getCategoryDetail(id, params) {
		return this.http.get(environment.offerPathV2 + "/category_details/" + id + "?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  	getMerchants(params, parent_project = false) {
		params["per_page"] = 200;
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPathV2 + "/merchants?elgble=true", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  	searchMerchants(params ,parent_project = false) {
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPathV2 + "/merchants/search", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  	redeem(offer, params) {
		return this.http.post(environment.offerPathV2 + "/offers/" + offer.id + "/redeem?elgble=true", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getProjects(projectId = null) {
		const httpOptions = {
    		headers: new HttpHeaders({
        	'exclude_project_id': 'true'
      	})};
		let environment_type = this.document.location.hostname.includes("sandbox") ? 'sandbox' : 'prod';
		return this.http.get(`${environment.offerPathV2}/projects/${projectId ? projectId : this.GlobalConstants.data.x_header[environment_type].main_project}?elgble=true`,httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  	}

	getPaymentLink(params){
		return this.http.post<any>(environment.apiUrlPaV1 +'payments', params).pipe(
		  map(res => res || []),
		  catchError(error => throwError(error))
		);
	}

	resendVoucher(id){
		return this.http.get(environment.offerPathV2 + "/offers/" + id + "/resend_voucher", {}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getRedemptions(params = {}) {
		return this.http.get(environment.offerPathV2 + "/redemptions?", { params: params }).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getTerms(id) {
		return this.http.get(environment.offerPathV2 + "/offers/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	confirmEmail(params){
		return this.http.put(environment.corePathV2 + "/user/verify_email",params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getMilestoneRewards() {
		return this.http.get(environment.offerPathV2+'/products').pipe(
			map(res => res || []),
			catchError(err => throwError(err))
		)
	}

	enrollMilestoneReward(productId) {
		return this.http.post(environment.offerPathV2+'/products/'+productId+'/enroll', {} )
			.pipe(
				map(res => {
					return res;
				}),
				catchError((err: HttpErrorResponse) => {
					return throwError(err);
			})
		)
	}

}
