import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ACTIVATED } from 'src/app/shared/consts';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(localStorage.getItem("currentUser")) {
          let userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")): null;
        if (userDetails && (userDetails.mobile && userDetails.status === ACTIVATED)) {
            return true;
          } else {
            this.router.navigate(["/"]);
          }
      } else {
        this.router.navigate(["/"]);
      }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(localStorage.getItem("currentUser")) {
        let userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")): null;
        if (userDetails && (userDetails.mobile && userDetails.status === ACTIVATED)) {
          return true;
        } else {
          this.router.navigate(["/"]);
        }
      } else {
        this.router.navigate(["/"]);
      }
  }
}
