<section
  class="footer-sec d-flex flex-column"
  [ngStyle]="{ background: globalConstants?.data.footer?.footer1Bg }"
>
  <div
    class="d-flex flex-wrap justify-content-between mar-top-20 mar-bot-20 custom-padding"
  >
    <div class="d-flex flex-row w100-xs">
      <img
        src="https://images.poshvine.com/razorpayx/Yes-Bank-logo-inverse.png"
        class="mar-right-8 logo"
        alt="yes-bank-logo"
      />
      <img
        src="https://images.poshvine.com/razorpayx/RZPX-light.png"
        class="logo"
        alt="razorpay-x-logo"
      />
      <div
        class="fnt-12 cfff f400 mar-left-17 align-self-center copyright-text d-block d-sm-none mar-top-1"
      >
        © {{ currentYear }}
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between mar-top-xs-16 links">
      <div class="blue-text align-self-center pointer text-capitalize">
        <a routerLink="/info/terms-and-conditions"> Terms & Conditions </a>
      </div>
      <div class="blue-text align-self-center pointer text-capitalize">
        <a routerLink="/info/privacy-policy"> Privacy Policy </a>
      </div>
      <div class="blue-text align-self-center pointer text-capitalize">
        <a routerLink="/info/customer-support"> Customer Support </a>
      </div>
    </div>
  </div>
  <div
    class="footer-2 fnt-14 f400 custom-padding"
    *ngIf="!isMobile"
    [ngStyle]="{ background: globalConstants?.data.footer?.footer2Bg }"
  >
    <div class="fnt-10 mar-bot-5">
      Disclaimer :Razorpay Merchant Rewards is a platform for communication of
      offers extended by Merchants to the Customers of Razorpay Merchant
      Rewards. Razorpay is only communicating the offers extended by Merchants
      to its Customers and not selling/rendering any of these Products/Services.
      Razorpay is not responsible for Sale/Quality/Features of the
      Products/Services under the offers
    </div>
    © Razorpay {{ currentYear }}
  </div>
</section>
