export const ACTIVATED: "activated" = "activated";

export const SUCCESS: "success" = "success";

export const SAAS: "saas" = "saas";

export const RAZORPAY: "razorpay" = "razorpay";

export const INDIA: "India" = "India";

export const BUSINESS: "Business" = "Business";

export const MERCHANT: "merchant" = "merchant";

export const MOBILE: "mobile" = "mobile";

export const FEATURED: "featured" = "featured";
