<div
  class="hide-scrollbar"
  [ngClass]="{
    'vh-100 overflow-auto': fullHeight,
    'main-content-height overflow-auto': !fullHeight
  }"
  id="scrollTotop"
>
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
<app-mobile-nav></app-mobile-nav>
