<div class="d-flex flex-column">
    <div class="pad-top-17 pad-bot-17 pad-left-17 pad-right-17 d-flex flex-row custom-border bottom" routerLink="/milestone-rewards" (click)="matDialogRef.close()">
        <img src="../../../../../assets/icons/milestone.svg" class="icon" />
        <div class="fnt-12 f400 cD1D2DA mar-left-10">
            Milestone Rewards
        </div>
    </div>
    <div class="pad-top-17 pad-bot-17 pad-left-17 pad-right-17 d-flex flex-row custom-border bottom" routerLink="/" (click)="matDialogRef.close()">
        <img src="../../../../../assets/icons/rewards.svg" class="icon" />
        <div class="fnt-12 f400 cD1D2DA mar-left-10">
            Explore Rewards
        </div>
    </div>
</div>