<div
  class="d-flex d-sm-none flex-row position-fixed bottom-0 w-100 pad-10 navigation-items-container row m-0"
>
  <div class="home d-flex flex-column navigation-item col" routerLink="/">
    <div
      class="icon m-auto"
      [style]="{
        borderColor:
          currentUrl != '/claimed-rewards' ? 'var(--blueTextColor)' : '#fff'
      }"
    >
      <span
        class="icon-star fnt-20"
        [ngStyle]="{
          color:
            currentUrl != '/claimed-rewards' ? 'var(--blueTextColor)' : '#fff'
        }"
      ></span>
    </div>
    <div
      class="fnt-12 f500 cfff mar-top-11 text-center"
      [ngClass]="{ 'blue-text': currentUrl != '/claimed-rewards' }"
    >
      Explore Rewards
    </div>
  </div>
  <div
    class="claimed-rewards d-flex flex-column navigation-item col"
    routerLink="/claimed-rewards"
  >
    <div class="icon m-auto">
      <span
        class="icon-historyicon fnt-20"
        [ngStyle]="{
          color:
            currentUrl === '/claimed-rewards' ? 'var(--blueTextColor)' : '#fff'
        }"
      ></span>
    </div>
    <div
      class="fnt-12 f500 cfff mar-top-11 text-center"
      [ngClass]="{ 'blue-text': currentUrl.includes('/claimed-rewards') }"
    >
      Claimed Rewards
    </div>
  </div>
</div>
