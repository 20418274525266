<div class="d-flex flex-column" [ngClass]="{'p-4': data.showCloseButton}" id="terms-and-conditions">
    <div class="d-flex justify-content-end" *ngIf="data.showCloseButton">
        <img src="../../../../assets/icons/close.svg" class="close-icon mt-auto mb-auto pointer" (click)="closeModal()" />
    </div>
    <div class="section">
        <h1>Terms & Conditions</h1>

        <ul>
            <li>Access to this portal is only for customers who have qualified for campaign and attended D2C SparkX, 2023.</li>
            <li>Portal is accessible through a valid OTP that will be triggered to customer's cell phone number that has been registered with Razorpay during the event.</li>
            <li>The offers can be downloaded by customers over a period of 3 weeks - 5th to 26th of October, 2023.</li>
            <li>TnC applicable for each offer as mentioned in details section.</li>
        </ul>
</div>